import { insertIf, join } from 'lib/helpers';
import Link from 'next/link';
import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
} from 'react';
import { ValueCallback } from 'types/callbacks';
import styles from './RiveTabList.module.css';

type RiveTabProps = {
  children: ReactNode;
  value: string;
  isSelected?: boolean;
  onSelect?: ValueCallback<string>;
};

export function RiveTab({
  children,
  value,
  isSelected,
  onSelect,
}: RiveTabProps) {
  return (
    <div
      className={join([
        styles.RiveTab,
        ...insertIf(Boolean(isSelected), styles.SelectedRiveTab),
      ])}
      onClick={() => onSelect && onSelect(value)}
    >
      {children}
    </div>
  );
}

type RiveLinkTabProps = {
  children: string;
  value: string;
  href: string;
  internal?: boolean;
  isSelected?: boolean;
  onSelect?: ValueCallback<string>;
};

export function RiveLinkTab({
  children,
  isSelected,
  internal,
  href,
}: RiveLinkTabProps) {
  if (internal) {
    return (
      <Link href={href}>
        <a
          className={join([
            styles.RiveTab,
            ...insertIf(Boolean(isSelected), styles.SelectedRiveTab),
          ])}
        >
          {children}
        </a>
      </Link>
    );
  }

  return (
    <a
      href={href}
      className={join([
        styles.RiveTab,
        ...insertIf(Boolean(isSelected), styles.SelectedRiveTab),
      ])}
    >
      {children}
    </a>
  );
}

type RiveTabListProps = {
  children: React.ReactNode;
  onTabSelect?: ValueCallback<string>;
  selectedTab?: string;
  fitContentWidth?: boolean;
};

export default function RiveTabList({
  children,
  onTabSelect,
  selectedTab,
  fitContentWidth = true,
}: RiveTabListProps) {
  const tabsWithOnSelect = Children.map<ReactNode, ReactNode>(
    children,
    (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          onSelect: onTabSelect,
          isSelected:
            selectedTab !== undefined && child.props.value === selectedTab,
        });
      }
      return child;
    }
  );

  return (
    <div
      className={join([
        styles.RiveTabList,
        ...insertIf(fitContentWidth, styles.FitContent),
      ])}
    >
      {tabsWithOnSelect}
    </div>
  );
}
