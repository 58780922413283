export function join(classes: string[]) {
  return classes.join(' ').trim();
}

export function insertIf(condition: boolean, elements: string) {
  return condition ? [elements] : [];
}

export function insertIfElse(
  condition: boolean,
  trueElements: string,
  falseElements: string
) {
  return condition ? [trueElements] : [falseElements];
}
