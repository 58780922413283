import { RuntimeLoader as CanvasRuntimeLoader } from '@rive-app/react-canvas';
import { RuntimeLoader as WebGLRuntimeLoader } from '@rive-app/react-webgl2';
import Menu from 'components/menuV2/Menu';
import { CommunityFilter } from 'components/menuV2/NavCommunityFilters';
import { insertIf, join } from 'lib/helpers';
import Head from 'next/head';
import { ReactNode } from 'react';
import { CommunityUserProfile } from 'types/community';
import Footer from './Footer';

import styles from './LayoutV2.module.css';

type Props = {
  title: string;
  noindex?: boolean;
  children: ReactNode;
  navTitle?: string;
  showCommunitySearch?: boolean;
  communitySearchValue?: string;
  showCommunityFilters?: boolean;
  selectedCommunityFilter?: CommunityFilter;
  showProfileBanner?: boolean;
  profile?: CommunityUserProfile;
  hideMenu?: boolean;
  noPadding?: boolean;
  backgroundColor?: string;
  canvasRuntime?: boolean;
};

export default function LayoutV2({
  title,
  navTitle,
  noindex,
  children,
  showCommunitySearch,
  communitySearchValue,
  showCommunityFilters,
  selectedCommunityFilter,
  showProfileBanner,
  profile,
  hideMenu,
  noPadding,
  backgroundColor,
  canvasRuntime,
}: Props) {
  const wasmURL = canvasRuntime
    ? // @ts-ignore
      CanvasRuntimeLoader.wasmURL
    : // @ts-ignore
      WebGLRuntimeLoader.wasmURL;

  return (
    <>
      <div
        className={join([
          styles.Container,
          ...insertIf(noPadding ?? false, styles.NoPadding),
        ])}
        style={{ backgroundColor: backgroundColor }}
      >
        <Head>
          <title>{title ? `Rive - ${title}` : 'Rive'}</title>
          <link rel="icon" href="/favicon.ico" />
          <link
            rel="preload"
            href={wasmURL}
            as="fetch"
            crossOrigin="anonymous"
          />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            name="description"
            content="Create and ship interactive animations to any platform."
          />
          {noindex && <meta name="robots" content="noindex" />}
        </Head>
        {hideMenu ? null : (
          <Menu
            showCommunitySearch={showCommunitySearch}
            communitySearchValue={communitySearchValue}
            showCommunityFilters={showCommunityFilters}
            selectedCommunityFilter={selectedCommunityFilter}
            showProfileBanner={showProfileBanner}
            profile={profile}
            navTitle={navTitle ?? title}
            canvasRuntime={canvasRuntime}
          />
        )}
        {children}
      </div>
      <Footer />
    </>
  );
}
