import { useEffect, useState } from 'react';
import NavIcon from './NavIcon';
import styles from './Menu.module.css';
import NavDropDown from './NavDropDown';
import NavCommunityFilters, { CommunityFilter } from './NavCommunityFilters';
import NavUserSection from './NavUserSection';
import { insertIf, join } from 'lib/helpers';
import { UserContextType } from 'contexts/user/types';
import NavProfileBanner from './NavProfileBanner';
import { CommunityUserProfile } from 'types/community';
import NavCommunitySearch from './NavCommunitySearch';
import featureFlags from 'lib/featureFlags';
import NavIconCanvas from './NavIconCanvas';

function useIsAtTop() {
  const [isAtTop, setIsAtTop] = useState<boolean>(true);

  useEffect(() => {
    function handleScroll() {
      setIsAtTop(window.pageYOffset === 0);
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return isAtTop;
}

type Props = {
  navTitle: string;
  showCommunitySearch?: boolean;
  communitySearchValue?: string;
  showCommunityFilters?: boolean;
  selectedCommunityFilter?: CommunityFilter;
  showProfileBanner?: boolean;
  profile?: CommunityUserProfile;
  canvasRuntime?: boolean;
};

export default function Menu({
  navTitle,
  showCommunitySearch = false,
  communitySearchValue,
  showCommunityFilters = false,
  selectedCommunityFilter,
  showProfileBanner = false,
  profile,
  canvasRuntime,
}: Props & UserContextType) {
  const isAtTop = useIsAtTop();

  function getNavHeight(): number {
    const defaultHeight = 78;
    if (typeof window !== 'undefined') {
      const menu = document.getElementById('NavMenu');
      const height = menu?.getBoundingClientRect().height;
      return height ?? defaultHeight;
    }
    return defaultHeight;
  }

  return (
    <>
      <div className={styles.Container}>
        <nav className={styles.NavMenu} id="NavMenu">
          <div className={styles.NavIcon}>
            {canvasRuntime ? <NavIconCanvas /> : <NavIcon />}
            <NavDropDown navTitle={navTitle} />
          </div>

          {(showCommunitySearch || showCommunityFilters) && (
            <div className={styles.CenterNav}>
              {featureFlags.communitySearch() && showCommunitySearch && (
                <div className={styles.NavCommunitySearch}>
                  <NavCommunitySearch prefillValue={communitySearchValue} />
                </div>
              )}

              {showCommunityFilters && (
                <div className={styles.NavCommunityFilters}>
                  <NavCommunityFilters selectedTab={selectedCommunityFilter} />
                </div>
              )}
            </div>
          )}

          {showProfileBanner && profile && (
            <div className={styles.NavProfileBanner}>
              <NavProfileBanner profile={profile} />
            </div>
          )}

          <div className={styles.NavUserSection}>
            <NavUserSection />
          </div>
        </nav>
        <div
          className={join([
            styles.NavMenuShadow,
            ...insertIf(isAtTop, styles.NavMenuShadowHidden),
          ])}
        />
      </div>
      <div
        className={
          showCommunityFilters ? styles.MenuPaddingFilters : styles.MenuPadding
        }
        style={{ height: getNavHeight() }}
      />
    </>
  );
}
