import { insertIf, join } from 'lib/helpers';
import { Children, ReactNode, cloneElement, isValidElement } from 'react';
import styles from './RiveDropDown.module.css';
import RiveDropDownItem from './RiveDropDownItem';
import RiveDropDownLinkItem from './RiveDropDownLinkItem';

export enum RiveDropDownSize {
  Standard,
  Large,
}

type Props = {
  children: ReactNode;
  size?: RiveDropDownSize;
};

export default function RiveDropDown({ children, size }: Props) {
  const childrenWithProps = Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (
      isValidElement(child) &&
      (child.type === RiveDropDownItem || child.type === RiveDropDownLinkItem)
    ) {
      return cloneElement(child, { size });
    }
    return child;
  });

  return (
    <div
      className={join([
        styles.RiveDropDown,
        ...insertIf(size === RiveDropDownSize.Large, styles.RiveDropDownLarge),
      ])}
    >
      <ul>{childrenWithProps}</ul>
    </div>
  );
}
