import { insertIf, join } from 'lib/helpers';
import { ReactNode } from 'react';
import { VoidCallback } from 'types/callbacks';
import { RiveDropDownSize } from './RiveDropDown';
import styles from './RiveDropDownItem.module.css';

type Props = {
  children: ReactNode;
  onClick?: VoidCallback;
  size?: RiveDropDownSize;
  highlight?: boolean;
};

export default function RiveDropDownItem({
  children,
  onClick,
  size,
  highlight = false,
}: Props) {
  return (
    <li
      className={join([
        styles.RiveDropDownItem,
        ...insertIf(
          size === RiveDropDownSize.Large,
          styles.RiveDropDownItemLarge
        ),
        ...insertIf(highlight, styles.Highlight),
      ])}
      onClick={() => onClick && onClick()}
    >
      {children}
    </li>
  );
}
