import { useState } from 'react';
import { useRouter } from 'next/router';
import styles from './NavCommunitySearch.module.css';
import { getCommunityPath } from 'lib/community';

type Props = {
  prefillValue?: string;
};

function cleanSearchQuery(query: string) {
  return query.replaceAll('/', ' ');
}

export default function NavCommunitySearch({ prefillValue = '' }: Props) {
  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState<string>(prefillValue);
  return (
    <input
      type="text"
      alt="Community search"
      placeholder="Search the Marketplace"
      value={searchQuery}
      className={styles.SearchField}
      onChange={(e) => setSearchQuery(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && searchQuery !== '') {
          e.preventDefault();
          router.push(
            getCommunityPath(`/search/${cleanSearchQuery(searchQuery)}`)
          );
        }
      }}
    />
  );
}
