import { CommunityShotItem, CommunityIds } from 'types/community';
import featureFlags from './featureFlags';

export function getShareLink(shotItem: CommunityShotItem) {
  const community = '/marketplace/';
  return `https://${process.env.NEXT_PUBLIC_SITE_DOMAIN}${community}${shotItem.urlSlug}/`;
}

export function getOEmbedLink(shotItem: CommunityShotItem) {
  const embedLink = encodeURIComponent(getShareLink(shotItem));
  return `https://${process.env.NEXT_PUBLIC_SITE_DOMAIN}/api/oembed?url=${embedLink}&format=json`;
}

export function getVideoLink(shotItem: CommunityShotItem) {
  const url = getShareLink(shotItem);
  return url + 'embed/?runtime=rive-renderer';
}

function getCommunityPostIdFromSlug(id: string | undefined) {
  if (id === undefined) {
    return undefined;
  }
  return parseInt(id.split('-')[0], 10);
}

/**
 * Parse the post and revision ids from a slug.
 *
 * Slug should be in the form `{communityPostId}-{communityRevisionId}-{string}`
 * E.g `111-222-my-post`
 *
 * @param slug - Slug we are parsing. If undefined, we return both ids as undefined
 * @returns
 */
export function getCommunityIdsFromSlug(
  slug: string | undefined
): CommunityIds {
  if (slug === undefined) {
    return {
      communityPostId: undefined,
      communityRevisionId: undefined,
    };
  }

  const chunks = slug.split('-');
  return {
    communityPostId: parseInt(chunks[0], 10),
    communityRevisionId: parseInt(chunks[1], 10),
  };
}

function pathWithPrefix(prefix: string, path: string): string {
  const slash = path.startsWith('/') || path === '' ? '' : '/';
  return `${prefix}${slash}${path}`;
}

// TODO refactor this to be marketplace, but this is all tied up with community posts, so not touching this now.
export function getCommunityPath(path: string = ''): string {
  return pathWithPrefix('/marketplace', path);
}

export function getForumPath(): string {
  return featureFlags.getCommunityPath();
}

export function getHelpCenterPath(path: string = ''): string {
  // TODO: should this now go to new docs?
  return pathWithPrefix('/community/doc', path);
}

export function getToSPath(): string {
  return getHelpCenterPath('terms-of-service/docG7vv2lLg8');
}

export function getPrivacyPolicyPath(): string {
  return getHelpCenterPath('privacy-policy/docPxxkGAUVc');
}

export function getRemixingPath(): string {
  return getHelpCenterPath('community-overview/doc9xIJYYXh2#remixing');
}

export function getFramerComponentPath(): string {
  return getHelpCenterPath('framer-and-rive/docNJ1eVCEmG');
}

export function getCommunityPostUrl(slug: string) {
  return getCommunityPath(slug);
}

export function getCommunityPostApiUrl(postSlug: string) {
  const communityPostId = getCommunityPostIdFromSlug(postSlug);

  // On a full refresh, next will not supply the id on the first render.
  // We can't make a request in this case, so we return null
  if (communityPostId === undefined) {
    return null;
  }

  return `/api/community-posts/${communityPostId}`;
}

export function getCommunityProfileUrl(username: string | undefined) {
  if (username === undefined) {
    return null;
  }
  const encodedUsername = encodeURIComponent(username);
  return `/api/profile/${encodedUsername}`;
}

export function getCommunityProfilePageUrl(username: string) {
  return `/@${encodeURIComponent(username)}`;
}

export function getCommunityProfilePostsUrl(id: string, before?: Number) {
  const endpoint = '/api/community-posts';
  const params: Record<string, string> = {
    owner_id: id,
    limit: '20',
  };
  if (before) {
    params.before = before.toString();
  }
  const queryString = new URLSearchParams(params).toString();
  return `${endpoint}?${queryString}`;
}

export function getCommunityPostReactionsUrl(communityPostId: number) {
  return `/api/community-posts/${communityPostId}/reactions`;
}

export function getCommunityPostReactedUrl(
  userId: Number,
  page?: Number,
  limit?: Number
): string {
  const endpoint = '/api/community-posts/reacted';
  const params: Record<string, string> = {
    owner_id: userId.toString(),
    ...(page && { page: page?.toString() }),
    ...(limit && { limit: limit?.toString() }),
  };

  const queryString = new URLSearchParams(params).toString();
  return `${endpoint}?${queryString}`;
}

export function getUpdateCommunityReactionUrl(
  communityPostId: number,
  reactionId: number
) {
  return `/api/community-posts/${communityPostId}/reactions/${reactionId}`;
}
