import styles from './RivePillButton.module.css';
import { VoidCallback } from 'types/callbacks';

type Props = {
  onClick?: VoidCallback;
  children: string;
};

export default function RivePillButton({ children, onClick }: Props) {
  return (
    <button
      className={styles.RivePillButton}
      onClick={() => onClick && onClick()}
    >
      {children}
    </button>
  );
}
