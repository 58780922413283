import { useRive } from '@rive-app/react-canvas';
import styles from './NavIcon.module.css';

export default function NavIconCanvas() {
  const { RiveComponent: RiveMark } = useRive({
    src: '/static/riv/rive-mark.riv',
    animations: 'Idle',
    autoplay: false,
  });

  return (
    <a href="/" className={styles.RiveMark}>
      <RiveMark width={24} height={26} />
    </a>
  );
}
