import { RiveFile, useRive } from '@rive-app/react-webgl2';
import styles from './NavIcon.module.css';
import { RiveFilesContext } from 'contexts/riveFile/RiveFilesContext';
import { useContext } from 'react';

export default function NavIcon() {
  const { riveMark } = useContext(RiveFilesContext);

  // @ts-ignore
  if (riveMark == null || riveMark?.buffer === undefined) {
    return <></>;
  }

  return <_NavIcon riveFile={riveMark} />;
}

type _Props = {
  riveFile: RiveFile;
};

function _NavIcon({ riveFile }: _Props) {
  const { RiveComponent: RiveMark } = useRive({
    riveFile: riveFile,
    animations: 'Idle',
    autoplay: false,
  });

  return (
    <a href="/" className={styles.RiveMark}>
      <RiveMark width={24} height={26} />
    </a>
  );
}
