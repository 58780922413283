import Avatar from 'components/common/Avatar';
import { CommunityUserProfile } from 'types/community';
import styles from './NavProfileBanner.module.css';

type Props = {
  profile: CommunityUserProfile;
};

export default function NavProfileBanner({ profile }: Props) {
  return (
    <div className={styles.NavProfileBanner}>
      <div className={styles.Avatar}>
        <Avatar data={profile} />
      </div>
      <div className={styles.Username}>{profile.username}</div>
    </div>
  );
}
