import RivePillButton from 'components/common/RivePillButton';

export default function NavSignIn() {
  return (
    <a
      href={process.env.NEXT_PUBLIC_EDITOR}
      target="_blank"
      rel="noopener noreferrer"
    >
      <RivePillButton>Get Started</RivePillButton>
    </a>
  );
}
