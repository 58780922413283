import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { RiveDropDownSize } from './RiveDropDown';
import RiveDropDownItem from './RiveDropDownItem';

import styles from './RiveDropDownLinkItem.module.css';

type Props = {
  children: ReactNode;
  href: string;
  internal?: boolean;
  size?: RiveDropDownSize;
};

export default function RiveDropDownLinkItem({
  children,
  href,
  internal,
  size,
}: Props) {
  const router = useRouter();
  const [isActive, setActive] = useState<boolean>(false);
  const item = (
    <RiveDropDownItem size={size} highlight={isActive}>
      {children}
    </RiveDropDownItem>
  );

  useEffect(() => {
    const path = router.pathname.replace(/^\/([^/]*).*$/, '$1');
    if (path === href.substring(1)) {
      setActive(true);
    }
  }, []);

  return internal ? (
    <Link href={href}>
      <a className={styles.Link}>{item}</a>
    </Link>
  ) : (
    <a className={styles.Link} href={href}>
      {item}
    </a>
  );
}
