import { COLORS } from './constants';

export const avatarColor = (index: number) => {
  const palette = [
    COLORS.avatarColor1,
    COLORS.avatarColor2,
    COLORS.avatarColor3,
    COLORS.avatarColor4,
  ];
  return palette[index % palette.length];
};
