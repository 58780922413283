import { useContext, useState } from 'react';
import Avatar from 'components/common/Avatar';
import RivePillButton from 'components/common/RivePillButton';
import RiveDropDown, {
  RiveDropDownSize,
} from 'components/drop-down/RiveDropDown';
import RiveDropDownDivider from 'components/drop-down/RiveDropDownDivider';
import RiveDropDownItem from 'components/drop-down/RiveDropDownItem';
import RiveDropDownLinkItem from 'components/drop-down/RiveDropDownLinkItem';
import { IsMobileContext } from 'contexts/IsMobile';
import { UserContext } from 'contexts/user';
import DropDownDelayable from './DropDownDelayable';
import DropDownUserDetails from './DropDownUserDetails';

import styles from './NavUser.module.css';
import { getCommunityProfilePageUrl } from 'lib/community';

export default function NavUser() {
  const { user, logout } = useContext(UserContext);
  const { isMobile } = useContext(IsMobileContext);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const hasNewNotification =
    user?.notificationCount !== undefined && user?.notificationCount > 0;

  if (user === undefined) {
    return null;
  }

  return (
    <div className={styles.NavUser}>
      <DropDownDelayable
        className={styles.Avatar}
        onDisplayChange={setMenuOpen}
      >
        <Avatar data={user} />
        {menuOpen && (
          <div className={styles.Menu}>
            <RiveDropDown size={RiveDropDownSize.Large}>
              <DropDownUserDetails user={user} />
              <RiveDropDownDivider />
              <RiveDropDownLinkItem href="/account/notifications" internal>
                <div className={styles.NotificationContainer}>
                  Notifications{' '}
                  {hasNewNotification && (
                    <div className={styles.NewNotification} />
                  )}
                </div>
              </RiveDropDownLinkItem>
              {user.username && (
                <RiveDropDownLinkItem
                  href={getCommunityProfilePageUrl(user.username)}
                  internal
                >
                  Your Profile
                </RiveDropDownLinkItem>
              )}
              <RiveDropDownDivider />
              <RiveDropDownLinkItem href="/account" internal>
                Account
              </RiveDropDownLinkItem>
              <RiveDropDownDivider />
              <RiveDropDownItem onClick={logout}>Sign Out</RiveDropDownItem>
            </RiveDropDown>
          </div>
        )}
      </DropDownDelayable>
      {!isMobile && (
        <a
          href={process.env.NEXT_PUBLIC_EDITOR}
          target="_blank"
          rel="noopener noreferrer"
        >
          <RivePillButton>Open Rive</RivePillButton>
        </a>
      )}
    </div>
  );
}
