import { useContext } from 'react';
import featureFlags from 'lib/featureFlags';
import isMobileContext from 'contexts/IsMobile/IsMobileContext';
import RiveTabList, { RiveLinkTab } from 'components/common/RiveTabList';
import { getCommunityPath } from 'lib/community';

export type CommunityFilter =
  | 'featured'
  | 'latest'
  | 'bones'
  | 'characters'
  | 'icons'
  | 'loaders'
  | 'for-hire';

type Props = {
  selectedTab?: CommunityFilter;
};

export default function NavCommunityFilters({ selectedTab }: Props) {
  const { isNarrow } = useContext(isMobileContext);

  return (
    <RiveTabList selectedTab={selectedTab} fitContentWidth={!isNarrow}>
      <RiveLinkTab
        value="featured"
        href={getCommunityPath('featured')}
        internal
      >
        Featured
      </RiveLinkTab>
      <RiveLinkTab value="latest" href={getCommunityPath('latest')} internal>
        Latest
      </RiveLinkTab>
      <RiveLinkTab
        value="for-hire"
        href={getCommunityPath('for-hire')}
        internal
      >
        For Hire
      </RiveLinkTab>
      {!featureFlags.communitySearch() && (
        <>
          <RiveLinkTab value="Bones" href={getCommunityPath('/tag/Bones')}>
            Bones
          </RiveLinkTab>
          <RiveLinkTab
            value="Characters"
            href={getCommunityPath('/tag/Characters')}
          >
            Characters
          </RiveLinkTab>
          <RiveLinkTab value="Icons" href={getCommunityPath('/tag/Icons')}>
            Icons
          </RiveLinkTab>
          <RiveLinkTab value="Loaders" href={getCommunityPath('/tag/Loaders')}>
            Loaders
          </RiveLinkTab>
        </>
      )}
    </RiveTabList>
  );
}
