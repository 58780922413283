import { useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { IsMobileContext } from 'contexts/IsMobile';
import { DropdownChevron } from 'components/common/svgIcons';
import RiveDropDown, {
  RiveDropDownSize,
} from 'components/drop-down/RiveDropDown';
import RiveDropDownLinkItem from 'components/drop-down/RiveDropDownLinkItem';
import DropDownDelayable from './DropDownDelayable';
import styles from './NavDropDown.module.css';
import {
  getCommunityPath,
  getForumPath,
  getHelpCenterPath,
} from 'lib/community';

type Props = {
  navTitle: string;
};

export default function NavDropDown({ navTitle }: Props) {
  const router = useRouter();
  const { isMobile } = useContext(IsMobileContext);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  function handleClick() {
    if (isMobile) {
      setMenuOpen((value) => !value);
    } else if (navTitle === 'Marketplace') {
      router.push(getCommunityPath());
    } else if (navTitle === 'Settings') {
      router.push('/account');
    } else {
      setMenuOpen((value) => !value);
    }
  }

  return (
    <DropDownDelayable
      className={styles.NavDropDown}
      onDisplayChange={setMenuOpen}
    >
      {navTitle.length > 0 && (
        <div className={styles.MenuTitle} onClick={handleClick}>
          <h1>{navTitle}</h1>
          <DropdownChevron />
        </div>
      )}
      {menuOpen && (
        <div className={styles.Menu}>
          <RiveDropDown
            size={isMobile ? RiveDropDownSize.Large : RiveDropDownSize.Standard}
          >
            <RiveDropDownLinkItem href="/">About</RiveDropDownLinkItem>
            <RiveDropDownLinkItem href={getCommunityPath()} internal>
              Marketplace
            </RiveDropDownLinkItem>
            <RiveDropDownLinkItem href={getForumPath()}>
              Community
            </RiveDropDownLinkItem>
            <RiveDropDownLinkItem href={getHelpCenterPath()}>
              Docs
            </RiveDropDownLinkItem>
          </RiveDropDown>
        </div>
      )}
    </DropDownDelayable>
  );
}
