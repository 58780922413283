import { useWindowSize } from 'lib/deviceUtils';
import FramerFooter from 'https://framer.com/m/Footer-DnO7.js@w9SgMvkLOzdwN1TllhOL';
import { RiveLogo } from './svgIcons';

const Footer = () => {
  const { width } = useWindowSize();

  if (width === 0) {
    return null;
  }

  return (
    <>
      <div
        style={{
          width: 'calc(100% - 80px)',
          height: '40px',
          padding: '60px 40px 0 40px',
          backgroundColor: '#111111',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '40px',
            maxWidth: width > 640 ? '1000px' : undefined,
            margin: 'auto',
          }}
        >
          <RiveLogo height={40} width={width > 640 ? undefined : '100%'} />
        </div>
      </div>
      <FramerFooter
        variant={width > 640 ? 'Variant 1' : 'Mobile'}
        style={{ width: '100%' }}
      />
    </>
  );
};

export default Footer;
