import { UserContext } from 'contexts/user';
import { useContext } from 'react';
import NavSignIn from './NavSignIn';
import NavUser from './NavUser';

export default function NavUserSection() {
  const { user } = useContext(UserContext);

  return user?.signedIn ? <NavUser /> : <NavSignIn />;
}
