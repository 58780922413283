import { useState } from 'react';
import { avatarColor } from 'lib/riveUtils';
import styles from './Avatar.module.css';

export interface AvatarData {
  name?: string | null;
  username?: string | null;
  avatar?: string | null;
  id?: number | null;
  ownerId?: number | null;
  isForHire?: boolean | null;
}

const Avatar = <T extends AvatarData>({
  data,
  fontSize,
}: {
  data: T;
  fontSize?: string | number;
}) => {
  const [hasError, setHasError] = useState<boolean>(false);

  if (!hasError && data.avatar) {
    return (
      <img
        className={styles.Avatar}
        src={data.avatar}
        alt="Avatar"
        onError={() => setHasError(true)}
      />
    );
  }
  const name = data.name ?? data.username ?? '?';
  const id = data.id ?? data.ownerId ?? 0;
  return (
    <div className={styles.Avatar} style={{ backgroundColor: avatarColor(id) }}>
      <div className={styles.AvatarInitial} style={{ fontSize }}>
        {name?.charAt(0).toUpperCase()}
      </div>
    </div>
  );
};

export default Avatar;
