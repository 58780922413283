import Avatar from 'components/common/Avatar';
import { MeResponse } from 'components/login/types';
import styles from './DropDownUserDetails.module.css';

type Props = {
  user: MeResponse;
};

export default function DropDownUserDetails({ user }: Props) {
  return (
    <div className={styles.Container}>
      <div className={styles.Avatar}>
        <Avatar data={user} />
      </div>
      <div className={styles.Details}>
        <div className={styles.Username}>{user.username}</div>
        <div className={styles.Email}>{user.email}</div>
        <div></div>
      </div>
    </div>
  );
}
