import { ComponentProps, ReactNode, useEffect, useRef, useState } from 'react';
import { ValueCallback } from 'types/callbacks';

type Props = {
  delay?: number;
  children: ReactNode;
  onDisplayChange: ValueCallback<boolean>;
};

export default function DropDownDelayable({
  delay = 100,
  children,
  onDisplayChange,
  ...rest
}: Props & ComponentProps<'div'>) {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  function onMouseEnter() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setMenuOpen(true);
  }

  function onMouseLeave() {
    timeoutRef.current = setTimeout(() => setMenuOpen(false), delay);
  }

  useEffect(() => onDisplayChange(menuOpen), [menuOpen]);

  return (
    <div onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave} {...rest}>
      {children}
    </div>
  );
}
